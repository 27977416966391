import axiosInstance from "@/axiosInstance";

export const forgotPassword = async (data) => {
  try {
    const response = await axiosInstance.post("/forgot-password", data);
    return response.data;
  } catch (error) {
    return { error: error.response.data.error };
  }
};
