const ServiceCard = ({ icon: Icon, title, description }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between">
      <div className="flex items-center">
        <Icon className="text-3xl text-gray-800" />
      </div>
      <h3 className="mt-4 text-lg font-semibold text-gray-900">{title}</h3>
      <p className="mt-2 text-gray-600">{description}</p>
      <div className="mt-4">
        <a
          href="#"
          className="text-gray-500 hover:text-gray-700 flex items-center"
        >
          Read More <span className="ml-2">→</span>
        </a>
      </div>
    </div>
  );
};

export default ServiceCard;
