import AuthContext from "@/context/authContext";
import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "./ui/button";
import { FaCog, FaBars, FaTimes } from "react-icons/fa";

export default function MainNavigation() {
  const { token, signOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    signOut();
    navigate("/");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="flex justify-between items-center py-4 px-6 md:px-10 relative bg-gradient-to-r from-gray-100 to-blue-50">
      {/* Logo */}
      <div className="text-2xl font-bold text-gray-800">Toseth</div>

      {/* Hamburger Menu for Mobile */}
      <div className="md:hidden">
        <button onClick={toggleMenu} aria-label="Toggle Menu">
          {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      {/* Navigation Links */}
      <nav className={`hidden md:flex space-x-6`}>
        <NavLink
          to="/"
          className="text-sm font-semibold text-gray-600 hover:text-gray-900"
        >
          Home
        </NavLink>
        <NavLink
          to="/services"
          className="text-sm font-semibold text-gray-600 hover:text-gray-900"
        >
          Services
        </NavLink>
        <NavLink
          to="/projects"
          className="text-sm font-semibold text-gray-600 hover:text-gray-900"
        >
          Projects
        </NavLink>
        <NavLink
          to="/pages"
          className="text-sm font-semibold text-gray-600 hover:text-gray-900"
        >
          Pages
        </NavLink>
        <NavLink
          to="/contact"
          className="text-sm font-semibold text-gray-600 hover:text-gray-900"
        >
          Contact
        </NavLink>
      </nav>

      {/* Action Buttons */}
      <div className="hidden md:flex items-center space-x-4">
        {!token && (
          <>
            <NavLink
              to="/signin"
              className="text-sm font-semibold text-gray-600 hover:text-gray-900"
            >
              LOGIN
            </NavLink>
            <Button className="py-2 px-4 text-sm font-medium bg-blue-500 hover:bg-blue-600 text-white rounded-md">
              <NavLink to="/signup">REGISTER</NavLink>
            </Button>
          </>
        )}
        {token && (
          <Button
            className="py-2 px-4 text-sm font-medium bg-blue-500 hover:bg-blue-600 text-white rounded-md"
            onClick={handleLogout}
          >
            Logout
          </Button>
        )}
        <div className="border-r-2 border-gray-300 h-6"></div>
        <FaCog
          className="text-gray-600 hover:text-gray-900 cursor-pointer"
          size={24}
        />
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="absolute top-16 left-0 w-full bg-gradient-to-r from-gray-100 to-blue-50 shadow-lg md:hidden z-50">
          <nav className="flex flex-col items-center space-y-6 py-8">
            <NavLink
              to="/"
              className="text-lg font-semibold text-gray-600 hover:text-gray-900"
              onClick={toggleMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/services"
              className="text-lg font-semibold text-gray-600 hover:text-gray-900"
              onClick={toggleMenu}
            >
              Services
            </NavLink>
            <NavLink
              to="/projects"
              className="text-lg font-semibold text-gray-600 hover:text-gray-900"
              onClick={toggleMenu}
            >
              Projects
            </NavLink>
            <NavLink
              to="/pages"
              className="text-lg font-semibold text-gray-600 hover:text-gray-900"
              onClick={toggleMenu}
            >
              Pages
            </NavLink>
            <NavLink
              to="/contact"
              className="text-lg font-semibold text-gray-600 hover:text-gray-900"
              onClick={toggleMenu}
            >
              Contact
            </NavLink>
          </nav>

          {/* Mobile Action Buttons */}
          <div className="flex flex-col items-center space-y-4 mt-4">
            {!token && (
              <>
                <NavLink
                  to="/signin"
                  className="text-lg font-semibold text-gray-600 hover:text-gray-900"
                  onClick={toggleMenu}
                >
                  LOGIN
                </NavLink>
                <Button className="py-2 px-4 text-lg font-medium bg-blue-500 hover:bg-blue-600 text-white rounded-md">
                  <NavLink to="/signup" onClick={toggleMenu}>
                    REGISTER
                  </NavLink>
                </Button>
                <br />
              </>
            )}
            {token && (
              <Button
                className="py-2 px-4 text-lg font-medium bg-blue-500 hover:bg-blue-600 text-white rounded-md"
                onClick={handleLogout}
              >
                Logout
              </Button>
            )}
          </div>
        </div>
      )}
    </header>
  );
}
