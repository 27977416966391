const HomeCard = ({ icon: Icon, title, description }) => {
  return (
    <div className="flex flex-col items-center text-center px-4 py-6 space-y-4">
      <Icon className="text-4xl" />
      <h3 className="text-lg font-semibold">{title}</h3>
      <p className="text-sm">{description}</p>
    </div>
  );
};

export default HomeCard;
