import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-12">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start">
        {/* Left Section: Logo and Contact Info */}
        <div className="flex flex-col items-start mb-8 md:mb-0">
          <h2 className="text-2xl font-bold text-white mb-4">
            <span className="text-blue-500">Toseth</span>
          </h2>
          <div className="bg-gray-800 p-4 rounded-lg">
            <div className="flex items-center mb-4">
              <FaMapMarkerAlt className="text-blue-500 mr-3" />
              <span>Gautam Buddha Uttar Pradesh</span>
            </div>
            <div className="flex items-center mb-4">
              <FaEnvelope className="text-blue-500 mr-3" />
              <span>hellozitu2011@gmail.com</span>
            </div>
            <div className="flex items-center">
              <FaPhoneAlt className="text-blue-500 mr-3" />
              <span>+91 8800652059</span>
            </div>
          </div>
        </div>

        {/* Middle Section: Other Pages */}
        <div className="mb-8 md:mb-0">
          <h3 className="text-lg font-semibold text-white mb-4">Other Pages</h3>
          <ul>
            <li className="mb-2">
              <a href="#" className="hover:text-blue-500">
                Home
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="hover:text-blue-500">
                About Us
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="hover:text-blue-500">
                Services
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="hover:text-blue-500">
                Projects
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-blue-500">
                Contact
              </a>
            </li>
          </ul>
        </div>

        {/* Right Section: Quick Links */}
        <div>
          <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
          <ul>
            <li className="mb-2">
              <a href="#" className="hover:text-blue-500">
                Privacy Policy
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="hover:text-blue-500">
                Term Of Service
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="hover:text-blue-500">
                Disclaimer
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="hover:text-blue-500">
                Credits
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-blue-500">
                FAQ
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-12 text-center text-sm text-gray-500 border-t border-gray-700 pt-6">
        <p>Powered by Toseth</p>
        <p>Copyright © 2022 All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
