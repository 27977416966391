import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/home";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import Root from "./pages/root";
import Error from "./pages/error";
import { checkAuthLoader } from "./lib/auth";
import { AuthProvider } from "./context/authContext";
import ForgotPasswordPage from "./pages/forgotPasswordPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "signin",
        element: <SignIn />,
        loader: checkAuthLoader,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPage />,
        loader: checkAuthLoader,
      },
      {
        path: "signup",
        element: <SignUp />,
        loader: checkAuthLoader,
      },
    ],
  },
]);
const App = () => {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
};

export default App;
