import axiosInstance from "@/axiosInstance";

export const loginUser = async (data) => {
  try {
    const response = await axiosInstance.post("/login", data);
    return response.data;
  } catch (error) {
    return { error: error.response.data.error };
  }
};
