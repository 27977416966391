import { createContext, useState, useEffect } from "react";
import { redirect } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    const savedUser = JSON.parse(localStorage.getItem("user"));

    if (savedToken) {
      setToken(savedToken);
    }
    if (savedUser) {
      setUserInfo(savedUser);
    }
  }, []);

  const signIn = (newToken, userInfo) => {
    localStorage.setItem("token", newToken);
    localStorage.setItem("user", JSON.stringify(userInfo));
    setToken(newToken);
    setUserInfo(userInfo);
  };

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setToken(null);
    setUserInfo(null);
    redirect("/");
  };
  const updateUserInfo = (businessForm) => {
    const updatedUserInfo = { ...userInfo, businessForm };
    localStorage.setItem("user", JSON.stringify(updatedUserInfo));
    setUserInfo(updatedUserInfo); // Update context
  };
  const updateUser = (updatedUserInfo) => {
    localStorage.setItem("user", JSON.stringify(updatedUserInfo));
    setUserInfo(updatedUserInfo); // Update context with new user info
  };
  return (
    <AuthContext.Provider
      value={{ token, signIn, signOut, userInfo, updateUserInfo, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
