import { Button } from "@/components/ui/button";
import { isTokenValid } from "@/lib/auth";
import { useNavigate } from "react-router-dom";
import myImage from "../assets/hero_section.png";
import mySecImage from "../assets/hero_section_sec.png";
import {
  FaMagic,
  FaVideo,
  FaTools,
  FaClipboardCheck,
  FaChartLine,
  FaUsers,
  FaCalendarAlt,
} from "react-icons/fa";
import HomeCard from "@/components/ui/home-card";
import ServiceCard from "@/components/ui/service-card";
import Footer from "@/components/footer";

const Home = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    if (isTokenValid()) {
      navigate("/dashboard");
    } else {
      navigate("/signin");
    }
  };

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-evenly items-center bg-gradient-to-r from-gray-100 to-blue-50 px-4 md:px-16 py-8 md:py-12">
        {/* Left Content */}
        <div className="max-w-lg text-center md:text-left mb-8 md:mb-0">
          <div className="bg-blue-100 text-blue-600 px-4 py-1 rounded-full inline-block text-xs md:text-sm font-semibold">
            Welcome To Toseth
          </div>
          <h1 className="text-3xl md:text-5xl font-bold text-gray-900 mt-4 md:mt-6">
            Unleash Your Creativity with AI-Powered Tools
          </h1>
          <p className="text-gray-600 mt-4 text-sm md:text-base">
            Discover a suite of AI-driven tools designed to elevate your content
            creation, scheduling, and analysis. Create, publish, and grow your
            audience like never before.
          </p>
          <Button
            className="mt-6 md:mt-8 bg-gray-900 text-white px-4 py-2 md:px-6 md:py-3 rounded-full text-sm font-semibold flex items-center justify-center md:justify-start hover:bg-gray-700"
            onClick={handleGetStarted}
          >
            Get Started <span className="ml-2">→</span>
          </Button>
        </div>

        {/* Right Content */}
        <div className="flex justify-center items-center w-full md:w-auto">
          <img
            src={myImage}
            alt="Toseth AI Magic Tools"
            className="w-40 h-40 md:w-64 md:h-64 lg:w-96 lg:h-96 object-contain"
          />
        </div>
      </div>

      {/* Features Section */}
      <div className="flex justify-center items-center px-4 py-12">
        <div className="bg-blue-500 text-white rounded-lg p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-6xl w-full">
          <HomeCard
            icon={FaMagic}
            title="AI Magic Tools"
            description="Create quick content with our AI video generator, enhance video quality with the AI upscaler, and generate engaging AI avatars and stickers."
          />
          <HomeCard
            icon={FaVideo}
            title="Creative Video Editing"
            description="Utilize our multi-platform video generator and advanced editing tools like speed ramping and keyframe animations."
          />
          <HomeCard
            icon={FaCalendarAlt}
            title="Content Scheduling"
            description="Plan and publish content across social media platforms with suggested hashtags and optimal posting times."
          />
          <HomeCard
            icon={FaChartLine}
            title="Performance Analytics"
            description="Track your post-performance with detailed analytics and reports, helping you refine your content strategies."
          />
        </div>
      </div>

      {/* About Section */}
      <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start bg-gradient-to-r from-gray-100 to-blue-50 px-4 md:px-10 py-12 md:py-16">
        {/* Left Section */}
        <img
          src={mySecImage}
          alt="Toseth Collaboration Tools"
          className="w-40 h-40 md:w-64 md:h-64 lg:w-96 lg:h-96 object-contain mb-8 lg:mb-0"
        />

        {/* Right Section */}
        <div className="lg:ml-16 max-w-md text-center lg:text-left">
          <div className="bg-blue-100 text-blue-600 px-4 py-1 rounded-full inline-block text-xs md:text-sm font-semibold">
            About Toseth
          </div>
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mt-4">
            Your Creative Hub
          </h1>
          <p className="text-gray-600 mt-4 text-sm md:text-base">
            Toseth offers a comprehensive platform for content creators and
            businesses to streamline their video editing, content scheduling,
            and analytics processes.
          </p>
          <ul className="mt-8 space-y-4">
            <li className="flex items-center space-x-3">
              <FaClipboardCheck className="text-blue-600" />
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  AI Magic Tools
                </h3>
                <p className="text-gray-600 text-sm">
                  AI-driven tools for video generation, upscaling, and more.
                </p>
              </div>
            </li>
            <li className="flex items-center space-x-3">
              <FaTools className="text-blue-600" />
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  Creative Video Editing
                </h3>
                <p className="text-gray-600 text-sm">
                  Advanced editing features and platform-specific templates.
                </p>
              </div>
            </li>
            <li className="flex items-center space-x-3">
              <FaUsers className="text-blue-600" />
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  Collaboration & Workflow
                </h3>
                <p className="text-gray-600 text-sm">
                  Manage teams and content approval with ease.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* Services Section */}
      <div className="bg-blue-500 text-white py-16 px-4 md:px-8">
        <div className="text-center mb-12">
          <h2 className="text-xs md:text-sm font-semibold uppercase tracking-widest">
            Our Services
          </h2>
          <h1 className="text-3xl md:text-4xl font-bold mt-2">
            Tailored Solutions for Creators and Businesses
          </h1>
          <p className="mt-4 max-w-xl mx-auto text-sm md:text-lg">
            Toseth provides tools and solutions for all your creative and
            business needs, from video editing to content scheduling and
            performance analysis.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto w-full">
          <ServiceCard
            icon={FaMagic}
            title="AI Magic Tools"
            description="Generate and enhance content with AI-powered tools."
          />
          <ServiceCard
            icon={FaVideo}
            title="Creative Video Editing"
            description="Edit videos with advanced tools and platform-specific templates."
          />
          <ServiceCard
            icon={FaCalendarAlt}
            title="Content Scheduling & Publishing"
            description="Schedule and publish content across multiple platforms with ease."
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
