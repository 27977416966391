import { useForm } from "react-hook-form";
import { useState } from "react";

import { forgotPassword } from "@/api/forgotPassword";

export const useForgotPasswordForm = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data) => {
    setError("");
    setSuccess("");
    setIsPending(true);
    try {
      const response = await forgotPassword(data);
      if (response.error) {
        setError(response.error);
      } else if (response.success) {
        setSuccess(response.success);
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsPending(false);
    }
  };

  return {
    isPending,
    error,
    success,
    register,
    handleSubmit,
    errors,
    control,
    onSubmit,
    setError,
    setSuccess,
  };
};
